/* ImageGalleryPage.css */
.headtop {
    display:flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px;
}
  
  .card-title {
    width: 65%;
  }
  
  .card-paragraph {
    width: 75%;
    margin-left: 71px;
  }

  .link{
    border: 1px solid black;
    border-radius:25px;
    text-decoration:none;
    padding: 5px 30px;
    height:40px
  }
  
  .main-image,
  .placeholder-box {
    object-fit: contain;
    background-color: rgb(54, 54, 54);
    height: 25vw;
    width: 25vw;
    border: 2px solid red;
  }
  
  /* Add more styles as needed */
  /* ImageGalleryPage.css */

.image-gallery-container {
  text-align: center;
}

.gallery-container {
  text-align: center;
  display: inline-block;
}

.headtop{
  justify-content: space-between;
 
}
.card-title{
    text-align:left;
}

.card-custom {
  width: 27.15vw;
  margin-bottom: 12px;
  padding: 1vw;
}

/* Responsive styles for screens less than or equal to 768px (mobile devices) */
@media (max-width: 768px) {
  .card-custom {
    width: 100vw;
    margin-bottom: 10px;
    padding: 0.5vw;
  }
  
  .main-image,
  .placeholder-box {
    height: 100vw;
    width: 100vw;
    border: 2px solid red;
  }
}

/* ImageGalleryPage.css222 */

/* Style for the carousel */
.carousel {
  width: 100%;
  max-width: 100%; 
  height: auto;
  overflow: hidden; 
  margin-bottom: 20px; 
}

/* Style for carousel items */
.carousel-item {
  display: flex; 
}

.carousel-item img {
  width: 100%;
  height: auto;
  background-color: black;
  object-fit: contain; /* Ensure the image covers the entire container */
  aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
  border: 2px solid red; 
}

/* Responsive styles for screens less than or equal to 768px (mobile devices) */
@media (max-width: 768px) {
  .carousel-item img {
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensure the image covers the entire container */
    aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
  }
}
/* Adjust carousel indicators position */
.carousel-indicators {
  bottom: -25px; 
}

/* Style for card container */
.card-container {
  padding: 10px;
}

/* Responsive styles for screens less than or equal to 768px (mobile devices) */
@media (max-width: 768px) {
  .carousel-item {
    flex-wrap: wrap; 
  }

  .carousel-item img {
    width: 100%; 
  }

 
}
