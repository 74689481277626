/* LocationDropdown.css */
/* LocationDropdown.css */
.left-align .item {
  text-align: left !important;
}

.location-dropdown-container {
    width: 70%;
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
  }
  
  .dropdown-label {
    font-size: 16px;
    margin-left: 10px;
  }
  .dropdown-content{
    text-align: left
  }

/* LocationDropdown.css */
.multi-select-container .item {
  text-align: left !important;
}

  .dropdown-select {
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 25px; /* Add border-radius */
  }
  
  .selected-location {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  .location-dropdown-container {
    text-align: left;
  }
  
  /* Additional styles for the dropdown content */
  .multi_select_drp {
    text-align: left !important; /* Use !important to override default styles */
  }
  
  
  /* Add additional styles as needed */
 
