.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.carousetitem{    margin: 20px;
  align-items: center;
  justify-content: center;
  display: flex;}


  .input{
    border-radius: 20px;
    height: 39px;
    padding: 6px;
    color: white;
    background-color: grey;
    font-size: medium;
    width: 200px !important;
  }

  ::placeholder {
    color: white;
  }

  .inputtext {
    border-radius: 20px;
    height: 39px;
    padding: 9px;
    color: white;
    background-color: grey;
    font-size: medium;
    border-top-color: gray;
    border-top-width: 0px;
    outline: none;
    border-left-width: 0px;
}
.modal-content {
  width: 894px !important
}

.fade.modal.show{
  padding-right: 312px !important;
}
.modelimage{
  width: 203px;
  max-height: 148px;
  object-fit: contain;
}

.userimg{
  
  max-height: 200px;
  object-fit: contain;

}
.modelbody{
  background-color: #00000071;
  width: 892px;
  height: 445px;
}
.twoinput{    display: flex;
  flex-direction: column;
  max-width: 237px;}
@media screen and (max-width: 480px) {
  
  .modelbody {
    background-color: #00000071;
    width: 343px;
    height: 494px;
    /* padding-right: 143px; */
    margin-left: 0px;
}
.input {
  border-radius: 20px;
  height: 39px;
  padding: 6px;
  color: white;
  background-color: grey;
  font-size: medium;
  margin-bottom: 6px;
  width: 200px !important;
}
.inputtext {
  border-radius: 20px;
  height: 39px;
  padding: 9px;
  color: white;
  background-color: grey;
  font-size: medium;
  border-top-color: gray;
  border-top-width: 0px;
  outline: none;
  border-left-width: 0px;
  margin-bottom: 6px;
}
.carousel{
  width: 112% !important;
}
.carouselitem {
  background: #ffffff !important;
  width: 73px !important;
  height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  border: 1px solid #ccc !important;
  border-radius: 14px !important;
  font-size: 13px !important;
  /* margin-right: 60px !important; */
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  margin-top: 20px !important;
  /* margin-right: -107px !important; */
  /* width: 375px !important; */
  margin-left: 1px !important;
  font-weight: 600;
}
.searchinput{width: 92% !important;
  padding: 12px !important;
  font-size: 16px !important;
  border-radius: 25px !important;
  border: 1px solid rgb(204, 204, 204) !important;}
}

.carousel{
  width: 62%;
}

.carouselitem{
  background: #ffffff;
  width: 150px; /* Set your preferred width */
  height: 40px; /* Set your preferred height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #ccc; /* Border color */
  border-radius: 25px; /* Border radius */
}


.searchinput{width: 60%;
  padding: 12px;
  font-size: 16px;
  border-radius: 25px;
  border: 1px solid rgb(204, 204, 204);}

  
