/* SearchBar.css */

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Adjust margin as needed */
}

.category-dropdown {
  margin-left: 10px;
}

.search-button {
  padding: 9px 20px; /* Adjust padding to 9px */
  border-radius: 25px;
  margin-left:-89px;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  width: 45vw;
}

input,
select,
button {
  margin: 0 5px; /* Adjust margin as needed */
}

input,
select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

button {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #007bff;
  border-radius: 15px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

/* Style for responsiveness (adjust as needed) */
@media (max-width: 768px) {
  form {
    flex-direction: row;
    align-items: stretch;
  }
  input {
    width: 48%;
  }
  .category-dropdown{
    margin-left:0;
  }
}
