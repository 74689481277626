.payment-container {
    gap: 0px;
    display: flex;
    padding-bottom: 50px;
    background-color: #161616;
    flex-direction: column; /* Set the flex direction to column */
    align-items: center; /* Center the items horizontally */
  }
  
  .payment-box {
    width: 50vw;
    flex: 1;
    margin: 10px;
    padding: 10px;
    text-align: center;
  }
  
  .payment-box > img {
    max-width: 25%;
    height: auto;
  }
  
  .payment-box > h2{
    width: 100%;
    color: white;
    background-color: rgb(22, 68, 237);
    font-size: 25px;
    border-radius: 25px;
    font-weight: 400;
    line-height: 1.5;
    padding: 20px;
  }

  /* Add media queries for responsiveness */
  @media (max-width: 1000px) {
    .payment-box {
      flex: 1 0 100%; /* Make the divs stack on top of each other */
      width: 98vw;
    }
  }
  