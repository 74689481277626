.about-us-container {
  background-color: #161616;
  height: 80vh;
}

.main {
  background-color: #161616;
  display: flex;
  flex-direction: row;
  padding-left: 5vw;
  padding-right: 5vw;
  gap: 50px;
  justify-content: center;
}

.sectiona {
  padding: 25px 100px 25px 100px;
  border-radius: 50px;
}

.about-us-title {
  text-align: center;
  padding: 4%;
  color: #fff;
  font-size: 25px;
  background-color: #242527;
  border: 2px solid white;
  border-radius: 50px;
}

.sectionc,
.sectionb {
  width: 100%; /* Full width on smaller screens */
  background-color: #161616;
  padding-bottom: 20vh;
}

.sectionb > .about-us-title,
.sectionc > .about-us-title {
  padding: 50px;
  line-height: 1.2;
}

.sectionb > .about-us-title > a,
.sectionc > .about-us-title > a {
  color: #fff;
  text-decoration: none;
}

.sectionb > img,
.sectionc > img {
  height: 300px;
  width: 300px;
  border: 2px solid white;
  border-radius: 50px;
  margin-bottom: 25px;
}

/* Media query for smaller screens (e.g., mobile devices) */
@media screen and (max-width: 768px) {
  .main {
    flex-direction: column; /* Stack sections on smaller screens */
    align-items: center; /* Center content on smaller screens */
  }

  .sectiona{
    padding: 25px;
    border-radius: 25px;
  }

  .sectionb{
    padding-bottom: 1px;
    margin-bottom: -30px;
  }

  .sectiona > .about-us-title{
    font-size: 15px;
    border-radius: 30px;
  }

  .sectionb,
  .sectionc {
    width: 100%; /* Full width on smaller screens */
  }

  .about-us-title{
    font-size: 15px;
    padding: 25px;
  }

  .sectionb > img{
    height:200px;
    width: 200px;

  }

  .sectionc > img{
    height:250px;
    width: 200px;

  }
}
