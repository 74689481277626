/* Header.css */
.header {
  /* overflow-y: hidden; */
  background-color: #ffffff;
  height: 240px;
}

.dropdown-label {
    cursor: pointer;
    font-size: 3vw;
    align-items: end;
    justify-content: right;
}

.header-content {
  width: 100%;
  max-width: 100vw; /* Set max-width to prevent horizontal overflow */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.left-component,
.center-component,
.right-component {
  padding: 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-component {
  justify-content: flex-start;
}

.center-component {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.center-component > img {
  height: 60px;
  margin-top: -70px;
  margin-bottom: -40px;
}

.right-component {
  justify-content: flex-end;
}

.center-component input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 35px;
  margin-right: 10px;
}

.center-component input::placeholder {
  color: #aaa;
}

.search-container {
  display: flex;
}

.center-component button {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.additional-component {
  flex-basis: 100%;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .header {
    margin-top: -30px;
    height: 180px;
  }

  .left-component {
    padding-left: 0px;
    margin-left: -25px;
    margin-top: -20px;
    justify-content: start;
    width: 40%;
}

  .center-component {
    flex-grow: 1;
  }

  .center-component > img {
    height: 40px;
  }
  .category-dropdown {
    margin-left:10px !important;
  }
}
