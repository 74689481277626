/* ContactUsPage.css */
.contact-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88vh;
    background-color: #161616;
}

.disclaimer-box {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 70%;
    background-color: #242527; /* Add your desired background color */
    color: #161616; /* Set text color */
    margin: 20px; /* Add margin for better spacing on small screens */
}



.disclaimer-box > p {
    text-align: justify;
    color: #fff;
    font-size: 20px;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
    .disclaimer-box {
        width: 90%; /* Adjust the width for smaller screens */
    }


    .disclaimer-box > p {
        font-size: 14px; /* Adjust paragraph font size */
    }
}

/* Add more media queries as needed for different screen sizes */
