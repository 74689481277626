/* .carouselitem {
    background: #f9f9f9;
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 25px;
}

@media screen and (max-width: 768px) {
    .carouselitem {
        height: 29px; 
        width: 80px; 
    }
} */

.carousetitem {
    align-items: center;
    display: flex;
    justify-content: center;
    /* margin-left: -10px; */
    padding-left: 10%;
    padding-right: 10%;
    /* margin-right: 0; */
}

 @media (max-width: 768px) {
    .carousetitem {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: -10px;
        margin-right: 0;
        padding: 0px;
    }
}

/* .carousetitem {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: -10px;
    margin-right: -0px;
} */

.wrapper {
    padding: 35px;
    display: flex;
    position: relative;
    overflow-x: hidden;
    max-width: 800px;
    background: #fff;
    border-radius: 13px;
    scroll-behavior: smooth; /* Apply smooth scrolling behavior here */
  }
  
  .wrapper .icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    display: flex; /* Change initial display to flex */
    align-items: center;
  }
  
  .left-icon {
      left: 0;
      background: linear-gradient(90deg, #fff 70%, transparent);
  }
    
  .right-icon {
      right: 0;
      justify-content: flex-end;
      background: linear-gradient(-90deg, #fff 70%, transparent);
  }
    
  .icon i {
    width: 55px;
    height: 55px;
    cursor: pointer;
    font-size: 1.2rem;
    text-align: center;
    line-height: 55px;
    border-radius: 50%;
  }
  
  .icon i:hover {
    background: #efedfb;
  }
  
  .left-icon i {
    margin-left: 15px;
  } 
  
  .right-icon i {
    margin-right: 15px;
  } 
  
  .tabs-box {
    display: flex;
    gap: 12px;
    list-style: none;
    overflow-x: hidden;
  }
  
  .tabs-box .tab {
    cursor: pointer;
    font-size: 1.18rem;
    white-space: nowrap;
    background: #f5f4fd;
    padding: 13px 20px;
    border-radius: 30px;
    border: 1px solid #d8d5f2;
  }
  
  .tabs-box .tab:hover {
    background: #efedfb;
  }
  
  .tabs-box .tab.active {
    color: #fff;
    background: #5372F0;
    border-color: transparent;
  }
